import React, { useState, useEffect } from "react";
import "../Register/Register.css";

const Register = () => {
  const [selectedTournament, setSelectedTournament] = useState("");
  const [tournaments, setTournaments] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    age: "",
    grade: "",
    phone: "",
    schoolname: "",
    address: "",
    district: "",
    pincode: "",
  });

  useEffect(() => {
    fetchTournaments();
  }, []);

  const fetchTournaments = () => {
    fetch("https://themithraa.com/RestApi/WebApi/tournament.php")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.status && data.status === true && data.Tournament) {
          // Sort tournaments by date in ascending order
          const sortedTournaments = data.Tournament.sort((a, b) => new Date(a.tourn_date) - new Date(b.tourn_date));
          setTournaments(sortedTournaments);
        } else {
          console.error("Error: Invalid response format:", data);
        }
      })
      .catch((error) => console.error("Error fetching tournaments:", error));
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();

    // Append form fields to FormData object
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    formDataToSend.append("tournament", selectedTournament);

    try {
      const response = await fetch(
        "https://themithraa.com/RestApi/WebApi/registertournament.php",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      const responseData = await response.json();

      if (response.ok && responseData.status) {
        alert("Registration successful!");
        // Clear form fields
        setFormData({
          name: "",
          email: "",
          gender: "",
          age: "",
          grade: "",
          phone: "",
          schoolname: "",
          address: "",
          district: "",
          pincode: "",
        });
      } else {
        alert("Registration failed. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting registration:", error);
      alert("Registration failed. Please try again later.");
    }
  };

  return (
    <div className="registerdetail">
      <div className="Registerpage">
        <h3 className="regtext">Tournament Register</h3>
        <form className="formdata needs-validation" onSubmit={handleSubmit}>
          <div className="formsize">
            <div className="form-group">
              <label htmlFor="tournament" className="form-label">
                Select Tournament
              </label>
              <select
                id="tournament"
                name="selectedTournament"
                className="form-control input mb-4"
                value={selectedTournament}
                onChange={(e) => setSelectedTournament(e.target.value)}
                required
              >
                <option value="">Select Tournament</option>
                {tournaments.map((tournament) => (
                  <option key={tournament.tourn_id} value={tournament.tourn_id}>
                    {tournament.tourn_name} - {tournament.tourn_date}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">Name</label>
              <input
                type="text"
                name="name"
                className="form-control input mb-4"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Email</label>
              <input
                type="email"
                name="email"
                className="form-control input mb-4"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="gender" className="form-label">
                Gender:
              </label>
              <select
                id="gender"
                name="gender"
                className="form-control input mb-4"
                value={formData.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">Age</label>
              <input
                type="number"
                name="age"
                className="form-control input mb-4"
                placeholder="Age"
                value={formData.age}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Grade</label>
              <input
                type="number"
                name="grade"
                className="form-control input mb-4"
                placeholder="Grade"
                value={formData.grade}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Phone</label>
              <input
                type="text"
                name="phone"
                className="form-control input mb-4"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">School Name</label>
              <input
                type="text"
                name="schoolname"
                className="form-control input mb-4"
                placeholder="School Name"
                value={formData.schoolname}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Address</label>
              <input
                type="text"
                name="address"
                className="form-control input mb-4"
                placeholder="Address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">District</label>
              <input
                type="text"
                name="district"
                className="form-control input mb-4"
                placeholder="District"
                value={formData.district}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="form-label">Pincode</label>
              <input
                type="text"
                name="pincode"
                className="form-control input mb-4"
                placeholder="Pincode"
                value={formData.pincode}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btns" style={{backgroundColor:'#6AA845',fontWeight:'bolder',color:'white'}}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
