import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import '../Achivers/Achivers.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export const Achivers = () => {
  const [achieversData, setAchieversData] = useState([]);

  useEffect(() => {
    fetch('https://themithraa.com//RestApi/WebApi/achievers.php')
      .then(response => response.json())
      .then(data => {
        if (data.status) {
          setAchieversData(data.Achievers);
        } else {
          console.error('Failed to fetch achievers');
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Auto slide every 2 seconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div className='achivers-container container'>
      <div className="about-us-title brushstroke-container fade-in">
        <span className="brushstroke-text">achievers</span>
      </div>
      <Slider {...settings}>
        {achieversData.map((achiever, index) => (
          <div className="achiever-card" key={index}>
            <div className="avatar-placeholder">
              {achiever.image ? (
                <img src={achiever.image} alt={achiever.name} className="avatar-image"/>
              ) : (
                <div className="avatar-fallback">No Image</div>
              )}
            </div>
            <h3>{achiever.name} <span className="year">'{achiever.age}</span></h3>
            <p className="house">{achiever.location}</p>
            <p className="time">{achiever.timing} <span className="pattern">({achiever.game})</span></p>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Achivers;
