import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Ourvalidcustomers/Ourvalidcustomer.css";

export const Ourvalidcustomers = () => {
  const [customers, setCustomers] = useState([]);

  // Fetch the data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://themithraa.com//RestApi/WebApi/customers.php');
        if (response.data.status) {
          setCustomers(response.data.Tournament);
        } else {
          console.error("Error fetching customer data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='Ourvalidcustomer container'>
      <div className="about-us-title brushstroke-container fade-in">
        <span className="brushstroke-text">Our Valued Customers</span>
      </div>
      <Slider {...settings}>
        {customers.length > 0 ? (
          customers.map((customer) => (
            <div key={customer.patner_id}>
              <img src={customer.img_name} alt={`customer-${customer.patner_id}`} />
            </div>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </Slider>
    </div>
  );
};

export default Ourvalidcustomers;
