import React, { useState } from 'react';
import logo from "../Images/mithraa.png";
import "../Footerdetail/Footerdetails.css";

export const Footerdetails = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comments: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;
    if (inputPhoneNumber.length <= 10) {
      setPhoneNumber(inputPhoneNumber);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataObj = new FormData();
      formDataObj.append('name', formData.name);
      formDataObj.append('email', formData.email);
      formDataObj.append('comments', formData.comments);
      formDataObj.append('phone', phoneNumber);

      const response = await fetch("https://themithraa.com/RestApi/WebApi/comments.php", {
        method: "POST",
        body: formDataObj
      });

      if (response.ok) {
        setFormData({
          name: '',
          email: '',
          comments: ''
        });
        setPhoneNumber('');
        window.alert("Form submitted successfully!");
        setErrorMessage('');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit form data");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage(error.message || "Failed to submit form data");
    }
  };

  return (
    <div className='footerdetail'>
      
      <div className='contact-section'>
        <img src={logo} alt="Mithraa logo" className="logo" />
        <div className='contact-info'>
          <p className='mt-5'><strong>Contact Us</strong></p>
          <p>📞 9087777971, 9087777972</p>
          <p>✉️ ravisankar.k@themithraa.com, prabakaran.k@themithraa.com, support@themithraa.com</p>
          <p><strong>Address : </strong>No.1C VGN Green Valley, Devadose Street, VGN Mahalakshmi Nagar, Thiruverkadu, Chennai-77.</p>
        </div>
      </div>

      <div className='form-section'>
        <form className='footerform' onSubmit={handleSubmit}>
          <h2>Message Us</h2>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Your Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="tel"
              className="form-control"
              placeholder="Enter Your Phone no"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Comments</label>
            <textarea
              className="form-control"
              placeholder="Enter Your Comments"
              maxLength={200}
              rows="3"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            ></textarea>
          </div>
          <button type="submit" className="submit-btn">Submit</button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
      </div>
    </div>
  )
}

export default Footerdetails;
