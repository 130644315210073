import React, { useEffect } from 'react';
import './WhatSportsStacking.css'
import cupstack from '../Images/cupstack.jpg';

export const WhatSportsStacking = () => {
  // Scroll Animation
  useEffect(() => {
    const handleScroll = () => {
      const reveals = document.querySelectorAll('.fade-in');

      reveals.forEach((reveal, index) => {
        const windowHeight = window.innerHeight;
        const elementTop = reveal.getBoundingClientRect().top;
        const elementVisible = 100;

        if (elementTop < windowHeight - elementVisible) {
          setTimeout(() => {
            reveal.classList.add('active');
          }, index * 300); // Delay by 300ms between each paragraph
        } else {
          reveal.classList.remove('active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='ourcoursedetail1'>
      <div className='container'>
        <div className="brushstroke-container fade-in">
          <span className="brushstroke-text">What Sports Stacking?</span>
        </div>
        <div className='coursecontent1'>
          <div className='coursecnt'>
            <p className="fade-in mt-2">Sports stacking, also known as cup stacking or speed stacking, is a competitive activity where participants stack and unstack a set of specially designed cups in specific sequences as quickly as possible. The sport requires hand-eye coordination, speed, and concentration. </p>
            <p className="fade-in mt-5">The most common sequences in sports stacking involve three different configurations:</p>
            <ul>
              <li>3-3-3 Stack: Three sets of three cups each are stacked and unstacked.</li>
              <li>3-6-3 Stack: A more complex sequence involving two sets of three cups and one set of six cups.</li>
              <li>Cycle Stack: A sequence that combines several different stacks, including the 3-3-3 and 3-6-3, into one continuous cycle.</li>
            </ul>
            <p className="fade-in mt-5">The sport is timed, and athletes compete to achieve the fastest time. Sports stacking can be done individually or in teams, and it's popular among all age groups, often featured in schools and organized competitions worldwide.</p>
         </div>
        </div>
      </div>
    </div>
  )
}

export default WhatSportsStacking;
